body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  padding: 0;
  margin: 0;
}
/*NabBar Styles*/ 
.nav-main{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px; 
  background-color: transparent;
  position: relative;   
}

.nav-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-icons a{
  text-decoration: none;
  color: black;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #151859; 
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  transition: all 0.3s ease;
  overflow: hidden; 
  position: static;    
}

.icon-container img {
  width: 30px; 
  height: 30px; 
  transition: transform 0.3s ease; 
}

.icon-text {
  display: none; /* Hide text initially */
  font-size: 14px; 
  /*white-space: nowrap; */
  margin-left: 10px; /* Space between icon and text */
  opacity: 0; 
  transition: opacity 0.3s ease; 
}

.icon-container:hover {
  width: auto; /* Allow the container to resize to fit the text */
  padding-left: 10px; /* Add padding to fit the text */
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 25px; /* Change to a pill shape on hover */
  border: 4px solid #F93901;
}

.icon-container:hover .icon-text {
  display: inline-block; /* Show text on hover */
  opacity: 1; /* Show text on hover */
}

.icon-container:hover img {
  transform: translateX(-10px); /* Move icon slightly left to make space for text */
}

.active-nav {
  border: 6px solid #F93901;
  border-radius: 30px;
}
/*NavBar Styles End*/

/*Hero Section Styles*/
.hero-container {      
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/assets/ai-generated-8663299_1280.png');
  margin: 0;
  padding: 50px 20px; /* Ajusta el padding según sea necesario */
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white; /* Color de texto para que sea visible sobre la imagen */
  text-align: center;    
} 
/*Hero Section End*/

/*Valores*/
.tarjeta {
  border: 1px solid gray;
  color: white;
  text-align: center;
  border-radius: 20px;
  box-shadow: -10px 10px 30px gray;
}

.tarjeta-main {
  background-color: #ed821f;    
}

.tarjeta-secundaria {
  background-color: #b2c663;
  transition: transform 0.3s ease-in-out;
}

.tarjeta-main-div{
  margin: 20px 0px 20px 0px;
}

.tarjeta-secundaria:hover {
  transform: scale(1.1);
}

.valores-main{   
  border-radius: 26px;
  background: linear-gradient(315deg, #d5e2e5, #feffff);
  box-shadow:  -5px -5px 10px #858d8e,
               5px 5px 10px #d9effa;    
}
/* Fin Valores*/

/* Frase */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  /*filter: contrast(.7);*/
}

.vista {
  height: 100vh;
}

.translate-middle {
  transform: translate(-50%, -50%);
}
/* Fin Frase*/

/* Cursos */
.card-course {
  border-radius: 26px;
  box-shadow:  -2px -2px 5px #565a5a,
               2px 2px 5px #565a5a;
  width: 18rem;
}
/* Fin Cursos*/

/* Newsletter*/
.main-newsletter img{
  width: 250px;
  height: auto;
}

.newsletter-container {
  border-radius: 50px;
  background: linear-gradient(145deg, #d5e2e5, #feffff);
  box-shadow:  20px 20px 60px #c9d5d8,
           -20px -20px 60px #ffffff;
  margin-top: 5px; 
}
/*Fin Newsletter*/

/* Testimonios */
.testimonio-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/assets/background-reviews.jpg');
  margin: 0;
  margin-top: 20px;
  padding: 50px 20px; /* Ajusta el padding según sea necesario */
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white; /* Color de texto para que sea visible sobre la imagen */
  text-align: center;
}

.testimonio a {
  font-size: 50px;
  font-weight: bold;
}
/*Fin Testimonio*/

/* footer */
.main-footer {
  background-color: #0652e9;
  color: white;
  font-size: 13px;
  border: 6px solid #b2c663;
}

.footer-logo img{
  height: 125px;
  width: auto;
}

.footer-paginas ul, .footer-servicios ul {
  list-style-type: none; /* Elimina los puntos de las listas */
  padding: 0; /* Elimina el padding por defecto */
  margin: 0; /* Elimina el margin por defecto */
}

.footer-paginas li, .footer-servicios li {
  margin: 0; /* Elimina el margin de los elementos li */
  text-align: left;
}

.footer-paginas a, .footer-servicios a {
  text-decoration: none; /* Opcional: elimina el subrayado de los enlaces */
  color: inherit; /* Opcional: hereda el color del texto del contenedor */
}

.redes-iconos img{
  height: 45px;
  width: auto;
}

.footer-contacto a{
  text-decoration: none;
  color: white;
}

.footer-contacto {
  text-align: center;
}
/*Fin Footer*/

/*Pagina Nosotros*/
.center-text {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 20px;
  text-align: left;
}
/*Fin Pagina Nosotros*/

/*Login*/
.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
.h-custom {
height: calc(100% - 73px);
}
@media (max-width: 450px) {
.h-custom {
height: 100%;
}
}
.form1{
  border: 2px solid black;
  padding: 20px;
  margin: 10px;
  border-radius: 15px;
}
/*Fin Login*/

/*AdminCursos*/
.adminCursos-section{
  border-top: 2px solid black;
}
.agregar-curso, .admin-cursos {
  border: 1px solid black;
  padding: 10px;
  border-radius: 15px;
}
/*Fin AdminCursos*/

/*Administracion*/

.admin-main-section{
  border-top: 1px solid black;
}
.admin-title{
  margin-top: 10px;  
}

.admin-opciones{
  border: 2px solid #151859;
  border-radius: 15px;
  padding: 10px;
  margin: 5px;
}

/*landing cursos*/

.card-course img {
  border: 4px solid #ed821f;
  border-radius: 20px;
  width: 95%;
}